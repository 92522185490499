<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        :color="(unseenMsg.length>0 ? 'error' : 'secondary')"
        overlap
        offset-x="10"
        offset-y="20"
        class="ms-4"
        dot
      >
        <v-icon v-bind="attrs" v-on="on">
          {{ mdiChatOutline }}
        </v-icon>
      </v-badge>
    </template>

    <v-list v-if="unseenMsg.length>0" subheader dense twoLine nav class="pt-2">
      <template v-for="(item, index) in unseenMsg">
        <router-link :to="`/message?id=${item.roomId}`">
          <v-list-item :key="index" style="height:3.2rem;">
            <v-list-item-avatar>
              <v-img :src="item.photo"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.userName"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="item.content"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="mt-1">
              <v-list-item-action-text
                class="pb-1"
                v-text="item.time">
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </router-link>

        <v-divider
          v-if="index < unseenMsg.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>

  </v-menu>
</template>

<script>
import { mdiChatOutline } from '@mdi/js'

export default {
  props: {
    unseenMsg: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data(){
    return {
      mdiChatOutline
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
